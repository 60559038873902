.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 13rem;
  padding: 2rem 6rem;
  border-right: 4px solid #087f5b;
}

.nav a {
  padding: 4rem;
  font-size: 2rem;
  border-radius: 12px;
  text-decoration: none;
  background-color: #087f5bb0;
  color: #fff;
}

.nav a:hover {
  background-color: #38d9a9b3;
}

@media (max-width: 57em) {
  .nav {
    padding: 2rem;
  }
  .nav a {
    padding: 2rem;
  }
}

@media (max-width: 42.5em) {
  .nav {
    padding: 1rem;
  }
  .nav a {
    padding: 1rem;
    font-size: 1.2rem;
  }
}

@media (max-width: 37em) {
  .nav {
    top: 15rem;
  }
}

@media (max-width: 24em) {
  .nav {
    padding: 0.2rem;
  }
  .nav a {
    padding: 1rem;
    font-size: 1rem;
  }
}
