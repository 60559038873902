.container {
  margin: auto;
  padding: 12rem;
  background: linear-gradient(45deg, #087f5b, #087f5b, #94d82d);
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  margin-bottom: 6rem;
}

.heading h2 {
  font-size: 3.8rem;
  text-align: center;
  color: #fff;
}

.heading p {
  font-size: 2.8rem;
  text-align: center;
  color: #fff;
}

.security {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  gap: 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.content h3 {
  font-size: 2.4rem;
  text-align: center;
  color: #fff;
}

.content p {
  font-size: 2rem;
  text-align: center;
  color: #fff;
}

.lock {
  width: 12rem;
  height: 12rem;
  color: #2c0000;
}

.ssl {
  width: 12rem;
  height: 12rem;
  color: green;
}

.warning {
  width: 12rem;
  height: 12rem;
  color: yellow;
}

.secure {
  width: 12rem;
  height: 12rem;
  color: green;
}

@media (max-width: 76em) {
  .security {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 62.5em) {
  .container {
    padding: 2rem;
  }
  .security {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 36.25em) {
  .security {
    grid-template-columns: repeat(1, 1fr);
  }
}
