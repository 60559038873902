.hero {
  background-image: url("../../images/hero-desktop.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 70rem;
  display: flex; /* Allow for child alignment */
  flex-direction: column;
  justify-content: space-around;
  padding: 0 4rem;
  overflow: hidden;
}

.heroText {
  font-size: 2.5rem;
  font-weight: 400;
  max-width: 80%;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  border-radius: 12px;
  opacity: 0; /* Hidden by default */
  transform: translateX(100%); /* Positioned off-screen to the right */
  transition: transform 1s ease-out, opacity 1s ease-out;
  align-self: flex-start; /* Default alignment (can be overridden individually) */
}

.heroText2 {
  font-size: 2.5rem;
  font-weight: 400;
  max-width: 80%;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  border-radius: 12px;
  opacity: 0; /* Hidden by default */
  transform: translateX(-100%); /* Positioned off-screen to the left */
  transition: transform 1s ease-out, opacity 1s ease-out;
  align-self: flex-end; /* Default alignment (can be overridden individually) */
}

.heroText3 {
  font-size: 2.5rem;
  font-weight: 400;
  max-width: 80%;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  border-radius: 12px;
  opacity: 0; /* Hidden by default */
  transform: translateX(100%); /* Positioned off-screen to the right */
  transition: transform 1s ease-out, opacity 1s ease-out;
  align-self: flex-start; /* Default alignment (can be overridden individually) */
}

.heroText4 {
  font-size: 2.5rem;
  font-weight: 400;
  max-width: 80%;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  border-radius: 12px;
  opacity: 0; /* Hidden by default */
  transform: translateX(-100%); /* Positioned off-screen to the right */
  transition: transform 1s ease-out, opacity 1s ease-out;
  align-self: flex-end; /* Default alignment (can be overridden individually) */
}

.heroText.visible,
.heroText2.visible,
.heroText3.visible,
.heroText4.visible {
  opacity: 1; /* Make the element visible */
  transform: translateX(0); /* Reset position to default */
}

@media (max-width: 62.5em) {
  .heroText,
  .heroText2,
  .heroText3,
  .heroText4 {
    font-size: 1.4rem;
  }
}

@media (max-width: 25em) {
  .heroText,
  .heroText2,
  .heroText3,
  .heroText4 {
    font-size: 1rem;
  }
}
