.container {
  margin: auto;
}

.heading {
  background: linear-gradient(45deg, #087f5b, #087f5b, #94d82d);
  padding: 8rem;
  text-align: center;
  color: #fff;
  font-size: 2.4rem;
  font-style: italic;
}

.terms {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  padding: 6rem;
}

.headingSecondry {
  font-size: 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.content h3 {
  font-size: 2rem;
}

.content p {
  font-size: 1.8rem;
}
