.container {
  margin: auto;
  padding: 8rem;
  width: 100%;
  height: 100%;
  background-image: url("../../images/invest-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.form {
  background: linear-gradient(45deg, #94d82dc5, #087f5bb6, #087f5bb6);
  margin: auto;
  max-width: 100rem;
  padding: 4rem;
  border-radius: 12px;
  position: relative;
}

.instruction {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin-bottom: 6rem;
}

.instruction p {
  font-size: 2rem;
  text-align: center;
  color: #fff;
}

.walletDetail {
  position: relative;
}

.wallet {
  font-size: 2rem;
  background-color: #fff;
  padding: 2rem;
  border-radius: 12px;
  position: relative;
}

.walletDetail button {
  padding: 0 2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  font-size: 2rem;
  border: none;
  border-radius: 12px;
  text-align: center;
}

.walletDetail button:active {
  background-color: grey;
}

.group {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  gap: 1rem;
  position: relative;
}

.input-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
  border-radius: 8px;
  padding: 0.4rem;
}

.icon {
  width: 3.8rem;
  height: 3.8rem;
  border-right: none;
  color: black;
  border-radius: 8px;
  margin-right: 0.2rem;
}

.group label {
  font-size: 2rem;
  display: flex;
  align-items: center;
  color: #fff;
}

.group input {
  width: 100%;
  width: 100%;
  border: 1px solid black;
  height: 3.8rem;
  font-size: 1.8rem;
  padding: 0 1rem;
  color: black;
  outline: none;
  border-radius: 8px;
}

.group select {
  width: 100%;
  height: 4rem;
  border: 1px solid black;
  border-radius: 8px;
  font-size: 1.8rem;
}

.action {
  display: flex;
  flex-direction: column;
}

.action button {
  font-size: 2.4rem;
  font-weight: 400;
  background-color: #087f5b;
  padding: 1rem 4rem;
  border-radius: 8px;
  color: #fff;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
  transition: all 500ms;
}

.action button:hover {
  transform: scale(1.05);
}

.action button:disabled,
.action button:disabled:hover,
.action button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transform: scale(1);
}

.invalid label {
  color: red;
}

@media (max-width: 47em) {
  .wallet {
    font-size: 1rem;
  }
}

@media (max-width: 42.5em) {
  .container {
    padding: 8rem 1rem;
  }
}
