.footer {
  margin: auto;
  background-color: #087f5b;
  background: linear-gradient(45deg, #087f5b, #212529);
  padding: 4rem;
  padding-bottom: 0;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.disclosure {
  max-width: 50rem;
}

.disclosure h2 {
  color: #fff;
  margin-bottom: 2rem;
}

.disclosure p {
  color: #fff;
  font-size: 1.4rem;
}

.nav {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6rem;
}

.navItem h2 {
  color: #fff;
  margin-bottom: 2rem;
  font-size: 2.4rem;
}

.navList {
  display: flex;
  flex-direction: column;
}

.navList a {
  text-decoration: none;
  color: #fff;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.9rem;
  letter-spacing: 2px;
  margin-bottom: 2rem;
}

.addressContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 4rem;
}

.addressTitle {
  font-size: 2.4rem;
  color: #fff;
}

.address {
  font-size: 2rem;
  color: #fff;
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}

.icon {
  width: 1.4rem;
  height: 1.4rem;
  color: #fff;
}

.copyright span {
  font-size: 1.4rem;
  color: #fff;
}

@media (max-width: 76em) {
  .navItem h2 {
    font-size: 1.2rem;
  }

  .navList a {
    font-size: 1rem;
  }
}

@media (max-width: 61.5em) {
  .content {
    flex-direction: column;
    align-items: flex-start;
  }

  .addressTitle {
    font-size: 1.8rem;
  }

  .address {
    font-size: 1.4rem;
  }
}
