.usage {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 8rem;
  background-color: #c3fae8;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6rem;
}

.title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #868e96;
}

.value {
  font-size: 4rem;
  font-weight: 800;
  color: #087f5b;
}

@media (max-width: 50em) {
  .title {
    font-size: 1.4rem;
  }

  .value {
    font-size: 3rem;
  }
}

@media (max-width: 37.5em) {
  .title {
    font-size: 1rem;
  }

  .value {
    font-size: 3rem;
  }

  .usage {
    padding: 2rem;
  }
}
