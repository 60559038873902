.plan {
  max-width: 30rem;
  width: 100%;
  height: 60rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #12b886;
  background: linear-gradient(45deg, #94d82d, #087f5b, #087f5b);
}

.plan h2 {
  font-size: 2.4rem;
  color: #fff;
}

.plan p {
  font-size: 2rem;
  color: #fff;
}

.plan a {
  text-decoration: none;
  background-color: #087f5b;
  padding: 2rem 4rem;
  color: #fff;
  font-size: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
