.partners {
  background-color: #f5f5f5;
  padding: 2rem;
}

.heading h2 {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 2rem;
}

.heading p {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.content {
  display: flex;
  justify-content: space-between;
}

.images {
  max-width: 16rem;
  max-height: 6rem;
}

.images img {
  width: 100%;
  height: 100%;
}

@media (max-width: 68.75em) {
  .images {
    max-width: 12rem;
    max-height: 4rem;
  }
}

@media (max-width: 50em) {
  .heading h2 {
    font-size: 2.4rem;
  }

  .heading p {
    font-size: 1.4rem;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    row-gap: 2rem;
  }
}

@media (max-width: 28.2em) {
  .content {
    grid-template-columns: 1fr 1fr;
  }
}
