.container {
  margin: auto;
}

.heading {
  background: linear-gradient(45deg, #087f5b, #087f5b, #94d82d);
  padding: 24rem 8rem;
  text-align: center;
  color: #fff;
  font-size: 2.4rem;
  font-style: italic;
}

.policy {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 0 8rem;
}

.subHeading {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 4rem;
}

.subHeading h2 {
  font-size: 3.8rem;
  text-align: center;
}

.subHeading p {
  font-size: 2rem;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem;
}

.content h2 {
  font-size: 2rem;
}

.content p {
  font-size: 2rem;
}

.content ul {
  list-style: disc;
}

.content ul ul {
  margin-left: 4rem;
}

.content li {
  font-size: 2rem;
}
