.container {
  padding: 18rem 2rem;
  margin: auto;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../images/contact.jpg");
  background-size: cover;
  background-position: center;
  opacity: 1;
  z-index: -1;
}

.form {
  max-width: 80rem;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 12px;
  padding: 2rem;
  position: relative;
}

.group {
  display: flex;
  flex-direction: column;
  -webkit-display: flex;
  -webkit-flex-direction: column;
  margin-bottom: 4rem;
  gap: 1rem;
  -webkit-gap: 1rem;
  position: relative;
}

.input-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-display: flex;
  -webkit-justify-content: space-between;
  -webkit-align-items: center;
  border: 1px solid #087f5b;
  border-radius: 8px;
  padding: 0.4rem;
}

.icon {
  width: 3.8rem;
  height: 3.8rem;
  border-right: none;
  color: #087f5b;
  border-radius: 8px;
  margin-right: 0.2rem;
}

.group label {
  font-size: 2rem;
  display: flex;
  align-items: center;
  -webkit-display: flex;
  -webkit-align-items: center;
}

.group input {
  width: 100%;
  border: none;
  height: 3.8rem;
  font-size: 1.8rem;
  padding: 0 1rem;
  background-color: rgba(255, 255, 255, 0.4);
  color: black;
  outline: none;
  border-radius: 8px;
}

.group textarea {
  height: 16rem;
  font-size: 1.8rem;
  border: none;
  background-color: rgba(255, 255, 255, 0.4);
  color: black;
  outline: none;
  width: 100%;
  padding: 0 1rem;
}

.form a {
  text-decoration: none;
  font-size: 2rem;
  color: #fff;
  position: absolute;
  bottom: -6rem;
  padding: 0.4rem 0.8rem;
  transition: all 500ms;
}

.form a:hover {
  color: blue;
}

.action button {
  font-size: 2.4rem;
  font-weight: 400;
  background-color: #087f5b;
  padding: 1rem 4rem;
  border-radius: 8px;
  color: #fff;
  border: none;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.6);
  transition: all 500ms;
}

.action button:hover {
  transform: scale(1.1);
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

.invalid label {
  color: #b40e0e;
}

.form span {
  color: #fff;
  font-size: 1.4rem;
  background-color: #b40e0e;
  padding: 0.5rem;
  display: inline-block;
  margin-bottom: 2rem;
  position: absolute;
  bottom: -6rem;
}

@media (max-width: 62.5em) {
  .container {
    padding: 2rem;
  }
}

@media (max-width: 37.5em) {
  .form {
    padding: 1.2rem;
  }

  .icon {
    width: 2rem;
    height: 2rem;
  }

  .group label {
    font-size: 1.2rem;
  }

  .group input {
    height: 3rem;
    font-size: 1.2rem;
  }

  .group textarea {
    height: 10rem;
    font-size: 1.2rem;
  }

  .action button {
    font-size: 1.2rem;
    padding: 1rem 2rem;
  }
}

@media (max-width: 32em) {
  .group input {
    width: 40rem;
  }
}

@media (max-width: 25.9em) {
  .group input {
    width: 30rem;
  }
}
