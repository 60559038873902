.investment {
  margin: auto;
  padding: 4rem;
  background: linear-gradient(45deg, #94d82d5b, #94d82d66, #087f5b7d);
  max-width: 70rem;
  margin-bottom: 4rem;
  border-radius: 8px;
  position: relative;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.icon {
  width: 4rem;
  height: 4rem;
  color: #fff;
}

.content span {
  font-size: 2rem;
  color: #fff;
}

.form {
  display: flex;
  flex-direction: column;
}

.group {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  gap: 1rem;
  position: relative;
}

.input-group {
  max-width: 80rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid black;
  border-radius: 8px;
  padding: 0.4rem;
}

.group label {
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.group input {
  max-width: 80rem;
  width: 100%;
  border: 1px solid black;
  height: 3.8rem;
  font-size: 1.8rem;
  padding: 0 1rem;
  background-color: #fff;
  color: black;
  outline: none;
  border-radius: 8px;
}

.action {
  display: flex;
  flex-direction: column;
}

.action button {
  font-size: 2.4rem;
  font-weight: 400;
  background-color: #087f5b;
  padding: 1rem 4rem;
  border-radius: 8px;
  color: #fff;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  transition: all 500ms;
}

button:disabled,
button:disabled:hover,
button:disabled:active {
  background-color: #ccc;
  color: #292929;
  border-color: #ccc;
  cursor: not-allowed;
}

.invalid input {
  border: 2px solid #b40e0e;
  background-color: #fddddd;
}

.invalid label {
  color: #b40e0e;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

@media (max-width: 42.5em) {
  .icon {
    width: 2rem;
    height: 2rem;
  }

  .content span {
    font-size: 1.2rem;
  }
}

@media (max-width: 28em) {
  .investment {
    padding: 1rem;
  }
}
