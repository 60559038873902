.nav {
  display: flex;
  align-items: center;
  position: relative; /* Ensure it's positioned relative to its container */
  left: -100%; /* Start completely off-screen to the left */
  animation: slideIn 1s ease-out forwards;
}

.nav a {
  text-decoration: none;
  color: #fff;
  font-family: "Bebas Neue", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.9rem;
  padding: 2.8rem 3rem;
  letter-spacing: 2px;
}

.nav a:hover {
  background-color: #212529;
  color: #fff;
}

.active {
  background-color: #212529;
  color: #fff;
}

@keyframes slideIn {
  from {
    left: -100%; /* Start off-screen */
  }
  to {
    left: 0; /* End at the final position */
  }
}

@media (max-width: 71.8em) {
  .nav a {
    font-size: 1.4rem;
    padding: 3.1rem 3rem;
  }
}

@media (max-width: 59em) {
  .nav a {
    font-size: 1.2rem;
    padding: 3.3rem 2rem;
  }
}

@media (max-width: 47.5em) {
  .nav {
    position: absolute;
    top: 8rem;
    left: 0;
    right: 0;
    height: 30rem;
    background-color: #12b886;
    z-index: 1000;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav a {
    width: 100%;
    text-align: center;
    padding: 1rem 2rem;
  }
}
