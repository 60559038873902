.container {
  margin: auto;
  background: linear-gradient(45deg, #087f5b, #087f5b, #94d82d);
}

.heading {
  padding: 2rem;
  font-size: 3.8rem;
  text-align: center;
  margin-bottom: 4rem;
  color: #fff;
}

.why {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.why p {
  font-size: 1.6rem;
  text-align: center;
  color: #fff;
}
