.container {
  margin: auto 2rem;
  margin-bottom: 8rem;
}

.container h2 {
  text-align: center;
  font-size: 3rem;
  margin-bottom: 4rem;
}

.task {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 3px solid grey;
  margin-bottom: 6rem;
  padding-bottom: 4rem;
  gap: 4rem;
}

.photo {
  width: 60rem;
  height: 40rem;
}

.photo img {
  width: 100%;
  height: 100%;
}

.content h3 {
  font-size: 2.4rem;
  font-weight: 600;
  max-width: 50rem;
  width: 100%;
  margin-bottom: 4rem;
}

.content p {
  font-size: 1.8rem;
  max-width: 50rem;
  width: 100%;
}

@media (max-width: 62.5em) {
  .content p {
    font-size: 1.2rem;
  }
  .photo {
    height: 20rem;
    width: 40rem;
  }
}

@media (max-width: 48.75em) {
  .task {
    flex-direction: column;
  }

  .photo {
    height: 35rem;
    width: 50rem;
  }

  .tasks > :nth-child(2),
  .tasks > :nth-child(4) {
    flex-direction: column-reverse;
  }
}

@media (max-width: 35em) {
  .photo {
    height: 20rem;
    width: 30rem;
  }

  .content p {
    max-width: 30rem;
  }
}
