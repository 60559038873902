.container {
  position: sticky;
  top: 0;
  z-index: 999;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  height: 8rem;
  padding: 2rem;
  background-color: #087f5b;
  position: relative;
}

.logo {
  text-decoration: none;
  color: #fff;
  font-family: "Edu AU VIC WA NT Arrows", cursive;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 2rem;
}

.icon {
  width: 4rem;
  height: 4rem;
  color: #fff;
  display: none;
}

@keyframes slideIn {
  from {
    left: -100%; /* Start off-screen */
  }
  to {
    left: 0; /* End at the final position */
  }
}

@media (max-width: 71.8em) {
  .logo {
    font-size: 1rem;
  }
}

@media (max-width: 47.5em) {
  .icon {
    display: block;
  }
}
