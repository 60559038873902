.container {
  margin: auto;
}

.heading {
  background: linear-gradient(45deg, #087f5b, #087f5b, #94d82d);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12rem 4rem;
  gap: 4rem;
  margin-bottom: 4rem;
}

.heading h2 {
  font-size: 3.8rem;
  color: #fff;
  text-align: center;
}

.heading p {
  font-size: 2.8rem;
  color: #fff;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 4rem;
  margin-bottom: 2rem;
}

.content h2 {
  font-size: 2rem;
}

.content p {
  font-size: 2rem;
}
