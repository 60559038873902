.about {
  margin: auto;
}

.mission {
  font-size: 3.8rem;
  padding: 6rem;
  background-color: #c3fae8;
  background: linear-gradient(45deg, #087f5b, #087f5b, #94d82d);
  text-align: center;
  margin-bottom: 8rem;
  color: #fff;
}

.content {
  background-color: #c3fae8;
  background: linear-gradient(45deg, #087f5b, #087f5b, #94d82d);
  padding: 6rem;
  margin-bottom: 8rem;
}

.content h2 {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 2.4rem;
  color: #fff;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.textContainer p {
  text-align: center;
  color: #fff;
  font-size: 2rem;
}

.members {
  background-color: #c3fae8;
  background: linear-gradient(45deg, white, black);
  padding: 6rem;
}

.members h2 {
  font-size: 2.4rem;
  text-align: center;
  margin-bottom: 4rem;
  padding: 2rem;
  background-color: #087f5b;
  color: #fff;
}

.profiles {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 4rem;
  justify-items: center;
}

/* Profile */
.profile {
  width: 30rem;
  height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  background-color: #087f5b;
  padding-bottom: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.photo {
  width: 30rem;
  height: 30rem;
}

.photo img {
  width: 100%;
  height: 100%;
}

.profile span {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
}

@media (max-width: 90em) {
  .profiles {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 68.75em) {
  .profiles {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 47.5em) {
  .profiles {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 26.25em) {
  .profile {
    width: 25rem;
    height: 45rem;
  }

  .photo {
    width: 25rem;
    height: 25rem;
  }

  .content {
    padding: 2rem;
  }

  .mission {
    padding: 2rem;
  }

  .members {
    padding: 2rem;
  }
}
