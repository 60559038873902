.container {
  margin: auto;
  background-color: #c3fae8;
  padding: 4rem;
  background: linear-gradient(45deg, #c3fae8, #087f5b);
}

.heading {
  background: linear-gradient(45deg, #087f5b, #087f5b, #94d82d);
  border-radius: 12px;
  padding: 2rem;
  margin-bottom: 4rem;
}

.heading h2 {
  text-align: center;
  color: #fff;
  font-size: 2.4rem;
  margin-bottom: 2rem;
}

.heading p {
  font-size: 1.8rem;
  color: #fff;
  text-align: center;
}

.plans {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  gap: 4rem;
}

@media (max-width: 62.5em) {
  .plans {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 43.75em) {
  .plans {
    grid-template-columns: repeat(1, 1fr);
  }
}
